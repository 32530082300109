<template>
	<v-container fluid>
		<v-container>
			<v-card>
				<v-card-title>{{ $t('terms.title') }}</v-card-title>
				<v-card-subtitle>{{ $t('terms.subtitle') }}</v-card-subtitle>
				<v-card-text class="justified">
					<v-row>
						<v-col>
							<div>
								<p><b>1.	UZAVRETIE KÚPNEJ ZMLUVY</b></p>
								<p><b>1.1.	KÚPNA ZMLUVA</b></p>
								<p>1.1.1. Kúpnou zmluvou sa rozumie právny úkon medzi predávajúcim (ďalej len „SECTRO s.r.o.“) a kupujúcim.<p>
								<p>1.1.2. Kúpna zmluva na tovar sa považuje za uzatvorenú, pokiaľ je splnená aspoň jedna z nasledujúcich podmienok: 
									<ul>
										<li>SECTRO s.r.o. potvrdí prijatie objednávky od kupujúceho mailom, telefonicky alebo podpisom,</li>
										<li>kupujúci alebo jeho zmocnenec potvrdil svojím podpisom na dodacom liste osobné prevzatie tovaru od SECTRO s.r.o.,</li>
										<li>kupujúci alebo jeho zmocnenec potvrdil svojím podpisom prevzatie tovaru zaslané od SECTRO s.r.o. poštou alebo kuriérom,</li>
										<li>kupujúci nevrátil v dohodnutej dobe vypožičaný tovar a SECTRO s.r.o. na vypožičaný tovar vystavil faktúru.</li>
									</ul>
								</p>
								<p>1.1.3. Uzavretím prvej kúpnej zmluvy sa stávajú tieto všeobecné obchodné podmienky záväzné pre SECTRO s.r.o. aj kupujúceho.<p>
								<p><b>1.2.	OPRÁVNENIE KONAŤ V MENE KUPUJÚCEHO</b></p>
								<p>1.2.1. Všetky úkony za kupujúceho môže pri obchodnom styku s SECTRO s.r.o. vykonávať štatutárny orgán kupujúceho, prípadne prokurista. Ostatné osoby zastupujúce kupujúceho musia predložiť na požiadanie identifikačný doklad a preukázať sa zamestnaneckým vzťahom, alebo poverením od kupujúceho na prebratie tovaru.<p>
								<p>1.2.2. Kupujúci je povinný priebežne informovať SECTRO s.r.o. o zmene svojho právneho postavenia, či podnikateľského oprávnenia a ďalej o zmenách vo vzťahu k osobám, ktoré sú poverené preberaním tovaru resp. zmenách zamestnávateľského pomeru takýchto osôb.<p>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div>
								<p><b>2.	OBJEDNÁVKA A PREVZATIE TOVARU</b></p>
								<p><b>2.1.	OBJEDNÁVKA TOVARU</b></p>
								<p>2.1.1. Objednávka je jednostranný záväzný právny úkon kupujúceho adresovaný SECTRO s.r.o.. Pokiaľ kupujúci uskutoční objednávku tovaru, zaväzuje sa uvedený tovar od SECTRO s.r.o. odobrať. </p>
								<p>2.1.2. Objednávku tovaru resp. služieb môže kupujúci zadať:
									<ul>
										<li>e-mailom na adresu <a href="mailto:sectro@sectro.sk" target="_blank">sectro@sectro.sk</a></li>
										<li>listom na adresu spoločnosti uvedenú na https://sectro.sk/contact</li>
										<li>prostredníctvom internetového obchodu na www.sectro.sk (len pre registrovaných obchodných partnerov).</li>
									</ul>
								</p>
								<p>2.1.3. Každá objednávka tovaru musí obsahovať najmä tieto základné informácie:
									<ul>
										<li>obchodné meno kupujúceho vrátane IČO, prípadne DIČ, ak je platcom DPH,</li>
										<li>meno kontaktnej osoby a telefonické spojenie na kontaktnú osobu,</li>
										<li>požadované miesto dodania (dodacia adresa), pokiaľ nebude tovar odobratý priamo v prevádzkach SECTRO s.r.o.,</li>
										<li>typové označenie tovaru podľa katalógu alebo cenníku SECTRO s.r.o.,</li>
										<li>objednávané množstvo každého jednotlivého druhu tovaru,</li>
										<li>pokiaľ má kupujúci jednorazovo dojednané špeciálne ceny, je na objednávanú dodávku tovaru povinný zaslať spolu s objednávkou aj obdržanú ponuku. </li>
									</ul>
								</p>
								<p>2.1.4. Kupujúci, ktorý je novým obchodným partnerom, musí uviesť taktiež: 
									<ul>
										<li>presné sídlo spoločnosti podľa výpisu z obchodného registra a priložiť kópiu výpisu z obchodného registra (pokiaľ je kupujúci právnická osoba)</li>
										<li>kópiu osvedčenia o registrácii od príslušného daňového úradu s číslom DIČ</li>
										<li>kópiu osvedčenia o registrácii od príslušného daňového úradu s číslom IČ DPH (ak je platcom DPH)</li>
									</ul>
								</p>
								<p>2.1.5. Objednávku potvrdzuje SECTRO s.r.o. mailom, telefonicky alebo písomne.</p>
								<p>2.1.6. Tovar je možné odobrať osobne na adrese firmy: SECTRO s.r.o. uvedenej na stránke www.sectro.sk/kontakty počas pracovných dní v čase od 9.00 do 16.00 hod.</p>
								<p>2.1.7.	Tovar je možné zaslať poštou alebo prepravnou službou na adresu objednávateľa. Iný druh dopravy je možný po vzájomnej dohode.</p>
								<p>2.1.8.	Pokiaľ je objednávaný tovar skladom, objednávky s požiadavkou na dodanie prepravnou službou, ktoré sú prijaté do 10.00 hod., sú zasielané v ten istý pracovný deň. Objednávky prijaté po 10.00 hod. sú zasielané nasledujúci pracovný deň.</p>
								<p>2.1.9.	Kupujúci je povinný pri prebraní zásielky od prepravcu ihneď prekontrolovať neporušenosť obalu. V prípade zjavného poškodenia spíše s prepravcom reklamačný protokol a zásielku odmietne alebo prevezme na vlastné riziko. U prepravcu spravidla nie je možné reklamovať dodávku dodatočne.</p>
								<p><b>2.2.	VLASTNÍCKE PRÁVO K TOVARU</b></p>
								<p>2.2.1. Vlastnícke právo k tovaru prechádza z SECTRO s.r.o. na kupujúceho až v momente úplného zaplatenia celej kúpnej ceny dodaného tovaru.</p>			
								<p>2.2.2. Hrozba poškodenia tovaru prechádza na kupujúceho momentom prevzatia tovaru od SECTRO s.r.o., alebo od prepravcu.<p>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div>
								<p><b>3.	CENA TOVARU A PLATBY ZA TOVAR </b></p>
								<p><b>3.1.	CENA TOVARU</b></p>
								<p>3.1.1. Cena za tovar je daná cenovou ponukou spracovanou obchodným oddelením SECTRO s.r.o. po dobu platnosti ponuky. V prípade, že ponuka neobsahuje informáciu o dobe platnosti, považuje sa za dobu platnosti 30 dní odo dňa vypracovania ponuky. </p>
								<p>3.1.2. Všetky uvedené ceny sú bez DPH.</p>
								<p><b>3.2.	PLATBY ZA TOVAR</b></p>
								<p>Kupujúci môže využiť nasledovné spôsoby platby za tovar:</p>
								<p>3.2.1. Platba bankovým prevodom na základe preddavkovej faktúry pred odberom tovaru – pri odbere tovaru je nutné predložiť doklad o zaplatení.</p> 
								<p>3.2.2. Platba zaslaním na dobierku prepravnou službou alebo poštou.</p>
								<p>3.2.3. Platba faktúrou – táto forma platby je určená pre stálych odberateľov, ktorí majú uhradené všetky faktúry po dátume splatnosti.</p>
								<p>3.2.4. Obvyklá doba splatnosti je 21 dní. </p> 
								<p><b>3.3.	UPOMIENKY A ZMLUVNÉ POKUTY</b></p>
								<p>3.3.1. Kupujúcemu, ktorý je v oneskorení s platbou za odobratý tovar zašle SECTRO s.r.o. upomienku s prehľadom doposiaľ nezaplatených faktúr po dátume splatnosti a s výzvou k zaplateniu.</p>
								<p>3.3.2. Pokiaľ kupujúci v primeranej dobe neuhradí faktúry po dátume splatnosti, je SECTRO s.r.o. oprávnený postúpiť svoju pohľadávku voči kupujúcemu tretej osobe bez súhlasu kupujúceho.</p>
								<p>3.3.3. Spoločnosť SECTRO s.r.o. je oprávnená požadovať od kupujúceho, ktorý je v omeškaní s platbou kúpnej ceny za tovar alebo jeho časť zmluvnú pokutu vo výške 0,1 % (jedna desatina percenta) z dlžnej čiastky za každý deň omeškania.</p>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div>
								<p><b>4.	VRÁTENIE TOVARU</b></p>
								<p><b>4.1. Vrátenie tovaru je možné iba za nasledovných podmienok pre kupujúceho:</b>
									<ul>
									<li>dopraví tovar do prevádzky spoločnosti SECTRO s.r.o.,</li>
										<li>predloží doklad o nákupe tovaru v spoločnosti SECTRO s.r.o. alebo jeho kópiu, </li> 
										<li>vráti tovar kompletný, vrátane obalov a technickej dokumentácie,</li>
										<li>vráti tovar čistý, bez poškodenia a s neporušenou pečatiacou nálepkou, </li>
										<li>vráti riadiace jednotky a ústredne po zresetovaní do továrenského nastavenia.</li>
									</ul>
								</p>
								<p><b>4.2.	Tovar bude prijatý späť za predpokladu, že kupujúci súhlasí so zníženou cenou spätného odkúpenia.</b></p>
								<p><b>4.3.	Na tovar, ktorý kupujúci vráti späť spoločnosti SECTRO s.r.o. bude vystavený dobropis. Zodpovedajúca čiastka bude pripísaná na účet kupujúceho alebo započítaná proti neuhradeným záväzkom kupujúceho.</b></p>
								<p><b>4.4.	Ceny spätného odkúpenia tovaru sú stanovené podľa počtu dní, ktoré uplynuli odo dňa zakúpenia tovaru takto:</b>
									<ul>
										<li>80 % pôvodnej ceny pri vrbátení do 15-tich dní,</li>
										<li>70 % pôvodnej ceny pri vrátení do 30-tich dní</li>
										<li>Vrátenie tovaru po lehote 30 dní odo dňa nákupu je možné výlučne po vzájomnej dohode . </li>
									</ul>
								</p>
								<p><b>4.5.	Pri vrátení nepoškodeného a nepoužitého výrobku bez originálneho obalu bude cena tovaru znížená o ďalších 10%.</b></p>
								<p><b>4.6.	V prípade existencie vzájomných záväzkov je predávajúci oprávnený vystaviť na príslušnú čiastku vzájomný zápočet.</b></p>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div>
								<p><b>5.	VÝPOŽIČKA TOVARU</b></p>
								<p><b>5.1.	KRÁTKODOBÁ VÝPOŽIČKA TOVARU</b></p>
								<p>5.1.1. O výpožičku tovaru môže požiadať iba kupujúci, ktorý je stály obchodný partner spoločnosti SECTRO s.r.o. a ktorý nemá pohľadávky po dobe splatnosti a má dobrú platobnú disciplínu.</p>
								<p>5.1.2. Tovar bude zapožičaný na obmedzenú dobu 7 pracovných dní, pokiaľ nie je dohodnuté inak</p>
								<p>5.1.3. Pokiaľ nebude zápožička vrátená v stanovenej dobe, má sa za to, že sa kupujúci rozhodol si tovar ponechať a spoločnosť SECTRO s.r.o. 
								je oprávnená zapožičaný tovar kupujúcemu vyfakturovať</p>
								<p>5.1.4. Pri vrátení vypožičaného tovaru je kupujúci povinný:
									<ul>
										<li>predložiť zápožičný list,</li>
										<li>vrátiť tovar kompletný, vrátane obalov a technickej dokumentácie,</li>
										<li>vrátiť tovar čistý, bez poškodenia a s neporušenou pečatiacou nálepkou, </li>
										<li>vrátiť riadiace jednotky a ústredne po zresetovaní do továrenského nastavenia.</li>
									</ul>
								</p>
								<p>5.1.5. Pokiaľ nebudú dodržané podmienky stanovené v bode 5.1.4, je spoločnosť SECTRO s.r.o. oprávnená vypožičaný tovar kupujúcemu vyfakturovať alebo tovar prevziať späť a kupujúcemu vyfakturovať náklady spojené s uvedením tovaru do pôvodného stavu.</p>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div>
								<p><b>6.	ZODPOVEDNOSŤ ZA CHYBY</b></p>
								<p><b>6.1.	ZÁRUKA NA TOVAR</b></p>
								<p>6.1.1. Za tovar zakúpený v ktorejkoľvek prevádzke spoločnosti SECTRO s.r.o. je poskytovaná záruka na akosť po dobu 24 mesiacov, okrem batérií a akumulátorov, kde sa poskytuje záruka6 mesiacov</p>
								<p>6.1.2. Záručná doba začína dňom vystavenia faktúry resp. prevzatia tovaru kupujúcim, podľa toho čo nastane skôr</p>
								<p>6.1.3. Po dobu záruky má kupujúci právo na bezplatné odstránenie chyby tovaru, nie je však oprávnený z dôvodu zistenej chyby tovaru odstúpiť od kúpnej zmluvy.</p>
								<p>6.1.4. Pokiaľ sa preukáže záručná reklamácia ako oprávnená, zaistí spoločnosť SECTRO s.r.o. bezplatnú opravu chyby tovaru alebo môže ponúknuť kupujúcemu výmenu tovaru za iný, prípadne ponúknuť primeranú zľavu z nákupnej ceny. </p> 
								<p>6.1.5. Kupujúci stráca nárok na uplatnenie záruky v prípade ak:
									<ul>
										<li>na zariadení bola porušená pečatiaca nálepka, výrobný štítok, označenie výrobného čísla alebo iné identifikačné znaky,</li>
										<li>došlo k neoprávnenému zásahu do elektrickej alebo mechanickej časti zariadenia,</li>
										<li>došlo k mechanickému, tepelnému, chemickému alebo elektrickému poškodeniu zariadenia,</li>
										<li>chyba zariadenia bola spôsobená neodbornou inštaláciou, prípadne nevhodným nastavením jeho pracovných parametrov (chybazapojenia, chybné naprogramovanie...). </li>
									</ul>
								</p>
								<p>6.1.6. Záruka poskytovaná spoločnosťou SECTRO s.r.o. na dodávaný tovar sa nevzťahuje na:
									<ul>
										<li>opotrebenie primerané dobe a spôsobu používania zariadenia,</li>
										<li>prevádzkovanie zariadenia mimo stanovené prevádzkové a technické podmienky alebo v rozpore s technickou dokumentáciou,</li>
										<li>priamym zásahom blesku, alebo úderom blesku v blízkom okolí (predpätia a vzdialená indukcia),</li>
										<li>živelnou pohromou (požiar, zaplavenie a podobne)</li>
										<li>nevhodným skladovaním, prepravovaním, alebo neodbornou manipuláciou.</li>
									</ul>
								</p>
								<p>6.1.7. Spoločnosť SECTRO s.r.o. taktiež nezodpovedá za priame, nepriame, či následné škody, ktoré kupujúcemu vzniknú v súvislosti s nákupom, inštaláciou, prevádzkou, alebo poruchou zakúpeného tovaru (náklady na montáž, demontáž, opravu, stratu očakávaného zisku, stratu času, dopravu a ďalšie odvodené škody)</p>
								<p><b>6.2.	ODOVZDANIE DO OPRAVY</b></p>
								<p>6.2.1Pri odovzdaní tovaru do opravy musí kupujúci uviesť čo najpresnejší a zrozumiteľný popis zistenej chyby.</p>
								<p>6.2.2 Ako doklad o prijatí tovaru do opravy vydá spoločnosť SECTRO s.r.o. reklamačný list, kde budú uvedené tieto informácie:
									<ul>
										<li>základné identifikačné údaje o kupujúcom,</li>
										<li>technické označenie tovaru odovzdaného do opravy a jeho množstvo,</li>
										<li>popis chyby uvádzanej kupujúcim,</li>
										<li>dátum prijatia do opravy</li>
										<li>na vyžiadanie klienta - predpokladaná cena opravy (pokiaľ sa jedná o pozáručnú opravu).</li>
									</ul>
								</p>
								<p><b>6.3.	REKLAMÁCIA TOVARU – ZÁRUČNÁ OPRAVA</b></p>
								<p>6.3.1. Reklamovať je možné iba tovar, ktorý bol kúpený v spoločnosti SECTRO s.r.o.. </p> 
								<p>6.3.2. Reklamáciu chyby tovaru v záručnej dobe je kupujúci povinný uplatniť bez zbytočného odkladu po jej zistení.</p>
								<p>6.3.3. Kupujúci musí v reklamačnom procese uplatňovať reklamáciu iba na chybný komponent, nie na kompletné zariadenie alebo na celú dodávku systému.</p>
								<p>6.3.4. Pre uplatnenie reklamácie musí kupujúci pri predaní tovaru do záručnej opravy:
									<ul>
										<li>priložiť kópiu dokladu o nákupe tovaru v spoločnosti SECTRO s.r.o., prípadne záručný list</li>
										<li>odovzdať reklamovaný tovar kompletný so všetkými funkčnými časťami.</li>
									</ul>
								</p>
								<p>6.3.5. Ak nebudú splnené podmienky uvedené v bode 6.3.4, reklamácia nebude uznaná a tovar bude vrátený naspäť kupujúcemu na jeho náklady alebo po dohode s ním, bude oprava vykonaná ako pozáručná.</p>
								<p>6.3.6. Pokiaľ sa reklamácia ukáže ako neoprávnená, je kupujúci povinný uhradiť spoločnosti SECTRO s.r.o. všetky náklady spojené s testovaním, opravou a administratívnym vybavovaním reklamácie zariadenia.</p>
								<p><b>6.4.	POZÁRUČNÁ OPRAVA</b></p>
								<p><b>6.4.1. SECTRO s.r.o. zaisťuje opravy zariadení z predajného sortimentu uvedeného v cenníku spoločnosti prostredníctvom vlastného servisného strediska, prípadne uplatnením reklamácie u dodávateľa resp. výrobcu.</p>
								<p><b>6.4.2. Do pozáručnej opravy je možné odovzdať tiež tovar, ktorý nebol kúpený u spoločnosti SECTRO s.r.o., v takom prípade je však nutné opravu vopred dohodnúť s pracovníkom servisného oddelenia.</p>
								<p><b>6.4.3. Kupujúci sa zaväzuje dohodnutú a vyfakturovanú cenu opravy uhradiť</p>
							</div>
						</v-col>
					</v-row>
 </v-card-text>
</v-card>
		</v-container>
	</v-container>
</template>

<script>
	export default {
		data: () => ({
		}),
	}
</script>

<style scoped>
.justified {
 text-align: justify;
}
</style>
